import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import Input from "../input/Input";
import CountriesDropdown, {
  Country,
} from "../countries-dropdown/CountriesDropdown";
import { useTranslation } from "react-i18next";
import { useTransition } from "react-transition-state";
import { UserContext } from "../../../contexts/continente-credentials/UserContext";

type InputType = "phone" | "email-phone";
type AutocompleteWith = "primaryContact" | "phone" | "email";

type UsernameInputProps = {
  type: InputType;
  value: string;
  disabled?: boolean;
  isError?: boolean;
  errorMsg?: string;
  isFilled: boolean;
  defaultCountryCode: string;
  defaultPhoneCountryCode: string;
  defaultValue?: string;
  autocompleteWith: AutocompleteWith;
  autoComplete?: string;
  onChange: (input: string, phoneNumberCode: string) => void;
};

const defaultProps = {
  defaultCountryCode: "PT",
  defaultPhoneCountryCode: "+351",
  onChange: (input: string, countryCode: string): void => void 0,
  value: "",
  autocompleteWith: "primaryContact",
  autoComplete: "username",
};

function isNumber(input: string) {
  if (input === null || input === undefined) {
    return false;
  }

  return !isNaN(Number(input)) && input.length > 2;
}

function UsernameInput(props: UsernameInputProps & typeof defaultProps) {
  const alertCircle =
    require("../../../assets/images/alert-circle.svg") as string;

  const { t } = useTranslation();
  const [focus, setFocus] = useState(false);
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState(
    props.defaultPhoneCountryCode
  );
  const [inputValue, setInputValue] = useState(props.value);
  const [countriesDropdownTransition, setCountriesDropdownTransition] =
    useTransition({
      timeout: 200,
      preEnter: true,
    });

  const userContext = useContext(UserContext);

  const withTransition = props.type === "email-phone";
  const transitionClass = withTransition
    ? `transition ${countriesDropdownTransition}`
    : "fixed";

  let label: string;
  switch (props.type) {
    case "phone":
      label = t("phonenumber_association.phonenumber_input");
      break;
    case "email-phone":
      label = t("login_mobile_email.number_email_input");
      break;
  }

  const onChange = (input: string, phoneNumberCountryCode: string) => {
    props.onChange(input, phoneNumberCountryCode);
  };

  useEffect(() => {
    if (props.autocompleteWith === "primaryContact") {
      if (props.type === "email-phone") {
        if (userContext.user.primaryContact === "phoneNumber") {
          setInputValue(props.defaultValue ?? userContext.user.phoneNumber);
          setPhoneNumberCountryCode(
            userContext.user.phoneNumberCode !== ""
              ? userContext.user.phoneNumberCode
              : phoneNumberCountryCode
          );
          onChange(
            props.defaultValue ?? userContext.user.phoneNumber,
            phoneNumberCountryCode
          );
        } else {
          setInputValue(userContext.user.email);
        }
      }
      if (props.type === "phone") {
        setInputValue(
          userContext.user.phoneNumber !== ""
            ? userContext.user.phoneNumber
            : props.defaultValue ?? props.value
        );
        setPhoneNumberCountryCode(
          userContext.user.phoneNumberCode !== ""
            ? userContext.user.phoneNumberCode
            : phoneNumberCountryCode
        );
        onChange(userContext.user.phoneNumber, phoneNumberCountryCode);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(value);

    const isCurrentInputValueNumber = isNumber(inputValue);
    const isNewInputValueNumber = isNumber(value);

    switch (props.type) {
      case "email-phone":
        if (!isCurrentInputValueNumber && isNewInputValueNumber) {
          setCountriesDropdownTransition(true);
        }

        if (isCurrentInputValueNumber && !isNewInputValueNumber) {
          setCountriesDropdownTransition(false);
        }
    }

    onChange(value, phoneNumberCountryCode);
  };

  useEffect(() => {
    if (
      userContext.user.primaryContact === "phoneNumber" &&
      userContext.user.phoneNumber !== ""
    ) {
      setCountriesDropdownTransition(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCountriesDropdownChange = (country: Country) => {
    userContext.updateUser({
      ...userContext.user,
      countryCode: country.code,
    });
    setPhoneNumberCountryCode(country.phoneNumberCode);
    onChange(inputValue, country.phoneNumberCode);
  };

  const onFocusChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setFocus(true);
  };

  const onBlurChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>): void => {
    setFocus(false);
  };

  return (
    <>
      <div className={`d-flex username-input ${transitionClass}`}>
        <div className="countries-dropdown">
          <CountriesDropdown
            onChange={onCountriesDropdownChange}
            isError={props.isError}
            isFocus={focus && !props.disabled}
            disabled={props.disabled}
            defaultCountryCode={props.defaultCountryCode}
            defaultPhoneNumberCode={props.defaultPhoneCountryCode}
          />
        </div>
        <div className="flex-grow-1">
          <Input
            name="userNameCC"
            labelText={label}
            isError={props.isError}
            errorMsg={props.errorMsg}
            isFilled={props.isFilled}
            disabled={props.disabled}
            onChange={onInputChange}
            value={inputValue}
            onBlur={onBlurChange}
            onFocus={onFocusChange}
            showError={false}
            autoComplete={props.autoComplete}
          />
        </div>
      </div>
      <div className="d-inline-flex align-items-center errorDiv">
        {props.isError && (
          <img src={alertCircle} alt="" className="alert-cicle" />
        )}
        <span className="errorLabel">{props.errorMsg}</span>
      </div>
    </>
  );
}

UsernameInput.defaultProps = defaultProps;

export default UsernameInput;
